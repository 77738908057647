
import { Component, Vue, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import Header from '@/modules/landingPage/components/layout/Header.vue';
import Footer from '@/modules/landingPage/components/layout/Footer.vue';
import FooterEU from '@/modules/landingPage/components/eu/layout/Footer.vue';
import BlogPostCard from '@/modules/landingPage/components/BlogPostCard.vue';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/* INTERFACES */
/* STORE */
import { blogHttpRepository } from '@/modules/landingPage/repositories/blog.httpRepository'
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

@Component({
  components: {
      Header,
      Footer,
      FooterEU,
      BlogPostCard,
      SvgIcon,
      VueSlickCarousel
  },
})
export default class Blog extends Vue {

    blogPosts = {items: [], total: 0, includes: {Asset: [], Entry: []}}
    categories = {items: []}
    currentCategory = null
    backgroundUrl= ''
    currentPage = 1;
    perPage = 9;
    searchInput= ''
    loading = true;
    loadingPosts = false;

    async mounted(){
        this.categories = await blogHttpRepository.getCategories();
        await this.fetchPaginatedBlogPosts();
        this.loading = false;
    }

    @Watch('currentPage')
    async watchCurrentPage(newVal: number){
        await this.fetchPaginatedBlogPosts();
    }

    async fetchPaginatedBlogPosts(noPagination?: boolean){
        this.loadingPosts = true;
        if (!noPagination)
            this.blogPosts = await blogHttpRepository.getPosts({skip: (this.currentPage - 1) * this.perPage, limit: this.perPage, 'fields.title[match]': this.searchInput});
        else
            this.blogPosts = await blogHttpRepository.getPosts({'fields.title[match]': this.searchInput});
        this.blogPosts.items.map(item => {
            item.fields.category = item.fields.category.map(cat =>{
                cat = this.entries.find(entry => entry.id === cat.sys.id).name
                return cat
            })
            return item
        })
        this.loadingPosts = false;
    }

    get blogPostsFilteredByCategory(){
        if (this.currentCategory){
            const result = this.blogPosts.items.filter(bp => bp.fields.category.includes(this.currentCategory))
            return {items: result, total: result.length}
        }else return {items: this.blogPosts.items, total: this.blogPosts.total}
    }


    async selectCurrentCategory(value: string | null){
        await this.fetchPaginatedBlogPosts(value !== null && true);
        this.currentCategory = value;
    }


    get assets(){
        const newAssets = {}
        this.blogPosts.includes.Asset.forEach(item => {
            const sysId = item.sys.id;
            newAssets[sysId] = item;
        });
        return newAssets
    }

    get entries(){
        return this.blogPosts.includes.Entry.map(entry => {
            return {id: entry.sys.id, name: entry.fields.name}
        })
    }

    get europeVersion(){
        return process.env.VUE_APP_EUROPE_VERSION
    }

}
